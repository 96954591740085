import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { OutGetV1TasksDTO } from '../../../../../../backend/dist/services/TaskService';
import { salesService, taskService } from '../../../../sdk';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  InPostV1CustomerDTO,
  InPostV1OrderDTO,
} from '../../../../../../backend/dist/services/SalesService';
import * as moment from 'moment';
import {
  CustomerSource,
  CustomerStatus,
} from '../../../../../../backend/dist/constants/enum';

type FormField = {
  formField: string;
  formId: string;
  required: boolean;
  icon?: string;
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  constructor(public authService: AuthService, private fb: FormBuilder) {}

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();
  filledRequired = false;
  listOfChildField: FormField[] = [
    {
      formField: '商品',
      formId: 'item',
      required: true,
      icon: 'star',
    },
    {
      formField: '單價',
      formId: 'rate',
      required: true,
      icon: 'dollar',
    },
    {
      formField: '數量',
      formId: 'qty',
      required: true,
      icon: 'inbox',
    },
  ];
  defaultInputChildField = {
    item: '',
    rate: 0,
    qty: 0,
  };

  taskList!: OutGetV1TasksDTO[];

  inputForm!: FormGroup;

  defaultInputField = {
    contact_email: '',
    contact_phone: '',
    first_name: '',
    last_name: '',
    task_id: null,
    source: null
  };
  listOfField: FormField[] = [
    {
      formField: '顧客姓氏',
      formId: 'last_name',
      required: true,
      icon: 'user',
    },
    {
      formField: '顧客名稱',
      formId: 'first_name',
      required: true,
      icon: 'user',
    },
    {
      formField: 'Email',
      formId: 'contact_email',
      required: true,
      icon: 'mail',
    },
    {
      formField: '聯絡電話',
      formId: 'contact_phone',
      required: true,
      icon: 'phone',
    },
  ];

  async ngOnInit(): Promise<void> {
    const res = await taskService.getV1Tasks({});
    this.taskList = res.data;

    this.inputForm = this.fb.group({
      ...this.defaultInputField,
    });
    this.listOfField.forEach((ele) => {
      if (ele.required) {
        this.requiredField.add(ele.formId);
      }

      this.inputForm.get(ele.formId)?.valueChanges.subscribe((value) => {
        this.filledRequired = true;

        this.requiredField.forEach((ele) => {
          const v = this.inputForm.get(ele)?.value;

          if (v == 0 || v == '') {
            this.filledRequired = false;
          }
        });

        if (value !== this.defaultInputField[ele.formId as never]) {
          this.editedField.add(ele.formId);
        } else if (this.editedField.has(ele.formId)) {
          this.editedField.delete(ele.formId);
        }
      });
    });
    this.inputForm.addControl('orderDetails', this.fb.array([]));

  }

  async submitForm() {
    const customer: InPostV1CustomerDTO = {
      contact_email: this.inputForm!.value.contact_email,
      contact_phone: this.inputForm!.value.contact_phone,
      first_name: this.inputForm!.value.first_name,
      last_name: this.inputForm!.value.last_name,
      source: this.inputForm!.value.source,
      status: CustomerStatus.EXISTING,
    };

    const inPostV1OrderDTD: InPostV1OrderDTO = {
      customer: customer,
      dateTime: moment().toISOString(),
      orderDetails: this.inputForm!.value.orderDetails,
      task_id: this.inputForm.get('task_id')?.value,
    };

    await salesService.postV1Order(inPostV1OrderDTD);

    alert('done');
  }
  get childForm(): FormArray {
    return this.inputForm.get('orderDetails') as FormArray;
  }

  addContent(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }

    this.childForm.push(this.fb.group({ ...this.defaultInputChildField }));
  }
}
