import { ResponseEntity } from '../../backend/dist/common/responseJSON'
import {OutGetV1BanksDTO as OutGetV1BanksDTOAccount , InGetV1BanksDTO as InGetV1BanksDTOAccount , OutPostV1BankDTO as OutPostV1BankDTOAccount , InPostV1BankDTO as InPostV1BankDTOAccount , OutGetV1TaxDTO as OutGetV1TaxDTOAccount , InGetV1TaxDTO as InGetV1TaxDTOAccount , OutPostV1TaxDTO as OutPostV1TaxDTOAccount , InPostV1TaxDTO as InPostV1TaxDTOAccount , OutGetV1FiscalDTO as OutGetV1FiscalDTOAccount , InGetV1FiscalDTO as InGetV1FiscalDTOAccount , OutPutV1FiscalDTO as OutPutV1FiscalDTOAccount , InPutV1FiscalDTO as InPutV1FiscalDTOAccount , OutGetV1ReportDTO as OutGetV1ReportDTOAccount , InGetV1ReportDTO as InGetV1ReportDTOAccount , OutGetV1StatisticsDTO as OutGetV1StatisticsDTOAccount , InGetV1StatisticsDTO as InGetV1StatisticsDTOAccount , OutGetV1IncomeDTO as OutGetV1IncomeDTOAccount , InGetV1IncomeDTO as InGetV1IncomeDTOAccount , OutPostV1IncomeDTO as OutPostV1IncomeDTOAccount , InPostV1IncomeDTO as InPostV1IncomeDTOAccount , OutGetV1ExpenseDTO as OutGetV1ExpenseDTOAccount , InGetV1ExpenseDTO as InGetV1ExpenseDTOAccount , OutPostV1ExpenseDTO as OutPostV1ExpenseDTOAccount , InPostV1ExpenseDTO as InPostV1ExpenseDTOAccount , OutGetV1CategoriesDTO as OutGetV1CategoriesDTOAccount , InGetV1CategoriesDTO as InGetV1CategoriesDTOAccount , OutPostV1CheckingDTO as OutPostV1CheckingDTOAccount , InPostV1CheckingDTO as InPostV1CheckingDTOAccount , } from '../../backend/dist/services/AccountService'
import {OutGetV1RecordsDTO as OutGetV1RecordsDTOAttend , InGetV1RecordsDTO as InGetV1RecordsDTOAttend , OutGetV1CheckinDTO as OutGetV1CheckinDTOAttend , InGetV1CheckinDTO as InGetV1CheckinDTOAttend , OutGetV1AbnormalDTO as OutGetV1AbnormalDTOAttend , InGetV1AbnormalDTO as InGetV1AbnormalDTOAttend , OutPostV1ScheduleDTO as OutPostV1ScheduleDTOAttend , InPostV1ScheduleDTO as InPostV1ScheduleDTOAttend , OutGetV1ScheduleDTO as OutGetV1ScheduleDTOAttend , InGetV1ScheduleDTO as InGetV1ScheduleDTOAttend , OutPutV1ScheduleDTO as OutPutV1ScheduleDTOAttend , InPutV1ScheduleDTO as InPutV1ScheduleDTOAttend , OutPostV1ShiftDTO as OutPostV1ShiftDTOAttend , InPostV1ShiftDTO as InPostV1ShiftDTOAttend , OutGetV1CheckoutDTO as OutGetV1CheckoutDTOAttend , InGetV1CheckoutDTO as InGetV1CheckoutDTOAttend , OutGetV1RecordDTO as OutGetV1RecordDTOAttend , InGetV1RecordDTO as InGetV1RecordDTOAttend , OutGetV1ShiftrecordDTO as OutGetV1ShiftrecordDTOAttend , InGetV1ShiftrecordDTO as InGetV1ShiftrecordDTOAttend , OutPutV1RecordDTO as OutPutV1RecordDTOAttend , InPutV1RecordDTO as InPutV1RecordDTOAttend , OutGetV1StatisticDTO as OutGetV1StatisticDTOAttend , InGetV1StatisticDTO as InGetV1StatisticDTOAttend , } from '../../backend/dist/services/AttendService'
import {OutGetV1CreateDTO as OutGetV1CreateDTOBackup , InGetV1CreateDTO as InGetV1CreateDTOBackup , OutGetV1ListDTO as OutGetV1ListDTOBackup , InGetV1ListDTO as InGetV1ListDTOBackup , } from '../../backend/dist/services/BackupService'
import {OutGetV1AccountsDTO as OutGetV1AccountsDTOBank , InGetV1AccountsDTO as InGetV1AccountsDTOBank , OutGetV1RecordsDTO as OutGetV1RecordsDTOBank , InGetV1RecordsDTO as InGetV1RecordsDTOBank , OutPostV1RecordDTO as OutPostV1RecordDTOBank , InPostV1RecordDTO as InPostV1RecordDTOBank , OutPutV1RecordDTO as OutPutV1RecordDTOBank , InPutV1RecordDTO as InPutV1RecordDTOBank , } from '../../backend/dist/services/BankService'
import {InGetRoleDTO as InGetRoleDTODev , } from '../../backend/dist/services/DevService'
import {} from '../../backend/dist/services/HelloService'
import {OutGetV1RecordsDTO as OutGetV1RecordsDTOLeave , InGetV1RecordsDTO as InGetV1RecordsDTOLeave , OutPostV1RecordDTO as OutPostV1RecordDTOLeave , InPostV1RecordDTO as InPostV1RecordDTOLeave , OutGetV1ApprovedDTO as OutGetV1ApprovedDTOLeave , InGetV1ApprovedDTO as InGetV1ApprovedDTOLeave , OutGetV1RejectedDTO as OutGetV1RejectedDTOLeave , InGetV1RejectedDTO as InGetV1RejectedDTOLeave , OutGetV1PendingDTO as OutGetV1PendingDTOLeave , InGetV1PendingDTO as InGetV1PendingDTOLeave , OutPutV1RecordDTO as OutPutV1RecordDTOLeave , InPutV1RecordDTO as InPutV1RecordDTOLeave , OutGetV1AlDTO as OutGetV1AlDTOLeave , InGetV1AlDTO as InGetV1AlDTOLeave , } from '../../backend/dist/services/LeaveService'
import {OutPostV1ProjectDTO as OutPostV1ProjectDTOProject , InPostV1ProjectDTO as InPostV1ProjectDTOProject , OutGetV1RecordsDTO as OutGetV1RecordsDTOProject , InGetV1RecordsDTO as InGetV1RecordsDTOProject , OutGetV1RecordDTO as OutGetV1RecordDTOProject , InGetV1RecordDTO as InGetV1RecordDTOProject , OutPutV1RecordDTO as OutPutV1RecordDTOProject , InPutV1RecordDTO as InPutV1RecordDTOProject , OutPostV1AssignDTO as OutPostV1AssignDTOProject , InPostV1AssignDTO as InPostV1AssignDTOProject , OutDelV1RecordDTO as OutDelV1RecordDTOProject , InDelV1RecordDTO as InDelV1RecordDTOProject , OutGetV1SchedulesDTO as OutGetV1SchedulesDTOProject , InGetV1SchedulesDTO as InGetV1SchedulesDTOProject , OutGetV1ProgressDTO as OutGetV1ProgressDTOProject , InGetV1ProgressDTO as InGetV1ProgressDTOProject , OutPostV1ConsultantDTO as OutPostV1ConsultantDTOProject , InPostV1ConsultantDTO as InPostV1ConsultantDTOProject , } from '../../backend/dist/services/ProjectService'
import {OutPostV1RecordDTO as OutPostV1RecordDTOPurchase , InPostV1RecordDTO as InPostV1RecordDTOPurchase , OutGetV1RecordsDTO as OutGetV1RecordsDTOPurchase , InGetV1RecordsDTO as InGetV1RecordsDTOPurchase , OutPutV1StatusDTO as OutPutV1StatusDTOPurchase , InPutV1StatusDTO as InPutV1StatusDTOPurchase , } from '../../backend/dist/services/PurchaseService'
import {OutGetV1RecordsDTO as OutGetV1RecordsDTOReimburse , InGetV1RecordsDTO as InGetV1RecordsDTOReimburse , OutPostV1RecordDTO as OutPostV1RecordDTOReimburse , InPostV1RecordDTO as InPostV1RecordDTOReimburse , OutGetV1ApprovedDTO as OutGetV1ApprovedDTOReimburse , InGetV1ApprovedDTO as InGetV1ApprovedDTOReimburse , OutGetV1RejectedDTO as OutGetV1RejectedDTOReimburse , InGetV1RejectedDTO as InGetV1RejectedDTOReimburse , OutGetV1RecordDTO as OutGetV1RecordDTOReimburse , InGetV1RecordDTO as InGetV1RecordDTOReimburse , OutPutV1RecordDTO as OutPutV1RecordDTOReimburse , InPutV1RecordDTO as InPutV1RecordDTOReimburse , OutDelV1RecordDTO as OutDelV1RecordDTOReimburse , InDelV1RecordDTO as InDelV1RecordDTOReimburse , OutGetV1StatisticsDTO as OutGetV1StatisticsDTOReimburse , InGetV1StatisticsDTO as InGetV1StatisticsDTOReimburse , OutGetV1StatisticDTO as OutGetV1StatisticDTOReimburse , InGetV1StatisticDTO as InGetV1StatisticDTOReimburse , } from '../../backend/dist/services/ReimburseService'
import {OutGetV1RecordsDTO as OutGetV1RecordsDTOSalary , InGetV1RecordsDTO as InGetV1RecordsDTOSalary , OutGetV1RecordDTO as OutGetV1RecordDTOSalary , InGetV1RecordDTO as InGetV1RecordDTOSalary , OutPutV1RecordDTO as OutPutV1RecordDTOSalary , InPutV1RecordDTO as InPutV1RecordDTOSalary , OutGetV1StatisticsDTO as OutGetV1StatisticsDTOSalary , InGetV1StatisticsDTO as InGetV1StatisticsDTOSalary , } from '../../backend/dist/services/SalaryService'
import {OutPostV1OrderDTO as OutPostV1OrderDTOSales , InPostV1OrderDTO as InPostV1OrderDTOSales , OutGetV1OrdersDTO as OutGetV1OrdersDTOSales , InGetV1OrdersDTO as InGetV1OrdersDTOSales , OutGetV1OrderDTO as OutGetV1OrderDTOSales , InGetV1OrderDTO as InGetV1OrderDTOSales , OutPutV1OrderDTO as OutPutV1OrderDTOSales , InPutV1OrderDTO as InPutV1OrderDTOSales , OutDelV1OrderDTO as OutDelV1OrderDTOSales , InDelV1OrderDTO as InDelV1OrderDTOSales , OutGetV1ViewDTO as OutGetV1ViewDTOSales , InGetV1ViewDTO as InGetV1ViewDTOSales , OutGetV1AccountsDTO as OutGetV1AccountsDTOSales , InGetV1AccountsDTO as InGetV1AccountsDTOSales , OutPostV1AssignDTO as OutPostV1AssignDTOSales , InPostV1AssignDTO as InPostV1AssignDTOSales , OutGetV1CustomersDTO as OutGetV1CustomersDTOSales , InGetV1CustomersDTO as InGetV1CustomersDTOSales , OutPostV1CustomerDTO as OutPostV1CustomerDTOSales , InPostV1CustomerDTO as InPostV1CustomerDTOSales , OutPutV1CustomerDTO as OutPutV1CustomerDTOSales , InPutV1CustomerDTO as InPutV1CustomerDTOSales , OutDelV1CustomerDTO as OutDelV1CustomerDTOSales , InDelV1CustomerDTO as InDelV1CustomerDTOSales , } from '../../backend/dist/services/SalesService'
import {OutGetV1BackupDTO as OutGetV1BackupDTOSystem , InGetV1BackupDTO as InGetV1BackupDTOSystem , OutPostV1BackupDTO as OutPostV1BackupDTOSystem , InPostV1BackupDTO as InPostV1BackupDTOSystem , } from '../../backend/dist/services/SystemService'
import {OutPostV1TaskDTO as OutPostV1TaskDTOTask , InPostV1TaskDTO as InPostV1TaskDTOTask , OutGetV1TasksDTO as OutGetV1TasksDTOTask , InGetV1TasksDTO as InGetV1TasksDTOTask , OutGetV1TaskDTO as OutGetV1TaskDTOTask , InGetV1TaskDTO as InGetV1TaskDTOTask , OutPutV1TaskDTO as OutPutV1TaskDTOTask , InPutV1TaskDTO as InPutV1TaskDTOTask , OutDelV1TaskDTO as OutDelV1TaskDTOTask , InDelV1TaskDTO as InDelV1TaskDTOTask , OutPostV1AssignDTO as OutPostV1AssignDTOTask , InPostV1AssignDTO as InPostV1AssignDTOTask , OutPostV1FollowDTO as OutPostV1FollowDTOTask , InPostV1FollowDTO as InPostV1FollowDTOTask , OutPostV1NotesDTO as OutPostV1NotesDTOTask , InPostV1NotesDTO as InPostV1NotesDTOTask , OutGetV1NotesDTO as OutGetV1NotesDTOTask , InGetV1NotesDTO as InGetV1NotesDTOTask , OutPostV1CustomerDTO as OutPostV1CustomerDTOTask , InPostV1CustomerDTO as InPostV1CustomerDTOTask , OutPutV1CustomerDTO as OutPutV1CustomerDTOTask , InPutV1CustomerDTO as InPutV1CustomerDTOTask , OutPutV1StatusDTO as OutPutV1StatusDTOTask , InPutV1StatusDTO as InPutV1StatusDTOTask , OutPostV1ConsultantDTO as OutPostV1ConsultantDTOTask , InPostV1ConsultantDTO as InPostV1ConsultantDTOTask , OutGetV1ConsultantDTO as OutGetV1ConsultantDTOTask , InGetV1ConsultantDTO as InGetV1ConsultantDTOTask , } from '../../backend/dist/services/TaskService'
import {OutGetV1RecordsDTO as OutGetV1RecordsDTOTrans , InGetV1RecordsDTO as InGetV1RecordsDTOTrans , OutGetV1UnpaidDTO as OutGetV1UnpaidDTOTrans , InGetV1UnpaidDTO as InGetV1UnpaidDTOTrans , OutGetV1UnreceivedDTO as OutGetV1UnreceivedDTOTrans , InGetV1UnreceivedDTO as InGetV1UnreceivedDTOTrans , OutGetV1PaidDTO as OutGetV1PaidDTOTrans , InGetV1PaidDTO as InGetV1PaidDTOTrans , OutGetV1ReceivedDTO as OutGetV1ReceivedDTOTrans , InGetV1ReceivedDTO as InGetV1ReceivedDTOTrans , OutPostV1ReceiptDTO as OutPostV1ReceiptDTOTrans , InPostV1ReceiptDTO as InPostV1ReceiptDTOTrans , OutGetV1StatisticsDTO as OutGetV1StatisticsDTOTrans , InGetV1StatisticsDTO as InGetV1StatisticsDTOTrans , } from '../../backend/dist/services/TransService'
import {OutPostV1FileDTO as OutPostV1FileDTOUpload , } from '../../backend/dist/services/UploadService'
import {ResLoginDTO as ResLoginDTOUser , LoginDTO as LoginDTOUser , UserSignUpDTO as UserSignUpDTOUser , OutGetV1StaffsDTO as OutGetV1StaffsDTOUser , InGetV1StaffsDTO as InGetV1StaffsDTOUser , OutGetV1DepartmentDTO as OutGetV1DepartmentDTOUser , InGetV1DepartmentDTO as InGetV1DepartmentDTOUser , OutPutV1StaffDTO as OutPutV1StaffDTOUser , InPutV1StaffDTO as InPutV1StaffDTOUser , } from '../../backend/dist/services/UserService'


export let api_origin = 'https://winghinghk.xyz'
// export let api_origin = 'http://localhost:8080'

export function callWithQuery(method: string, url: string, input: object) {
  return fetch(api_origin + url + '?' + new URLSearchParams(Object.entries(input)), {
    method,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
  }).then(res=>res.json())
    .catch(err=>({error: String(err)}))
}

export function callWithBody(method: string, url: string, input: object) {
  return fetch(api_origin + url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(input),
  }).then(res=>res.json())
    .catch(err=>({error: String(err)}))
}

export function callWithFormData(method: string, url: string, input: BodyInit) {
  return fetch(api_origin + url, {
    method,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: input,
  }).then(res=>res.json())
    .catch(err=>({error: String(err)}))
}


export let accountService = {
    getV1Banks(input: InGetV1BanksDTOAccount): Promise<ResponseEntity<OutGetV1BanksDTOAccount>> {
        return callWithQuery('GET', '/api/account/v1/Banks', input)
    },
    postV1Bank(input: InPostV1BankDTOAccount): Promise<ResponseEntity<OutPostV1BankDTOAccount>> {
        return callWithBody('POST', '/api/account/v1/Bank', input)
    },
    getV1Tax(input: InGetV1TaxDTOAccount): Promise<ResponseEntity<OutGetV1TaxDTOAccount>> {
        return callWithQuery('GET', '/api/account/v1/Tax', input)
    },
    postV1Tax(input: InPostV1TaxDTOAccount): Promise<ResponseEntity<OutPostV1TaxDTOAccount>> {
        return callWithBody('POST', '/api/account/v1/Tax', input)
    },
    getV1Fiscal(input: InGetV1FiscalDTOAccount): Promise<ResponseEntity<OutGetV1FiscalDTOAccount>> {
        return callWithQuery('GET', '/api/account/v1/Fiscal', input)
    },
    putV1Fiscal(input: InPutV1FiscalDTOAccount): Promise<ResponseEntity<OutPutV1FiscalDTOAccount>> {
        return callWithBody('PUT', '/api/account/v1/Fiscal', input)
    },
    getV1Report(input: InGetV1ReportDTOAccount): Promise<ResponseEntity<OutGetV1ReportDTOAccount[]>> {
        return callWithQuery('GET', '/api/account/v1/Report', input)
    },
    getV1Statistics(input: InGetV1StatisticsDTOAccount): Promise<ResponseEntity<OutGetV1StatisticsDTOAccount>> {
        return callWithQuery('GET', '/api/account/v1/Statistics', input)
    },
    getV1Income(input: InGetV1IncomeDTOAccount): Promise<ResponseEntity<OutGetV1IncomeDTOAccount[]>> {
        return callWithQuery('GET', '/api/account/v1/Income', input)
    },
    postV1Income(input: InPostV1IncomeDTOAccount): Promise<ResponseEntity<OutPostV1IncomeDTOAccount>> {
        return callWithBody('POST', '/api/account/v1/Income', input)
    },
    getV1Expense(input: InGetV1ExpenseDTOAccount): Promise<ResponseEntity<OutGetV1ExpenseDTOAccount[]>> {
        return callWithQuery('GET', '/api/account/v1/Expense', input)
    },
    postV1Expense(input: InPostV1ExpenseDTOAccount): Promise<ResponseEntity<OutPostV1ExpenseDTOAccount>> {
        return callWithBody('POST', '/api/account/v1/Expense', input)
    },
    getV1Categories(input: InGetV1CategoriesDTOAccount): Promise<ResponseEntity<OutGetV1CategoriesDTOAccount[]>> {
        return callWithQuery('GET', '/api/account/v1/Categories', input)
    },
    postV1Checking(input: InPostV1CheckingDTOAccount): Promise<ResponseEntity<OutPostV1CheckingDTOAccount>> {
        return callWithBody('POST', '/api/account/v1/Checking', input)
    },
}

export let attendService = {
    getV1Records(input: InGetV1RecordsDTOAttend): Promise<ResponseEntity<OutGetV1RecordsDTOAttend[]>> {
        return callWithQuery('GET', '/api/attend/v1/Records', input)
    },
    getV1Checkin(input: InGetV1CheckinDTOAttend): Promise<ResponseEntity<OutGetV1CheckinDTOAttend>> {
        return callWithQuery('GET', '/api/attend/v1/Checkin', input)
    },
    getV1Abnormal(input: InGetV1AbnormalDTOAttend): Promise<ResponseEntity<OutGetV1AbnormalDTOAttend>> {
        return callWithQuery('GET', '/api/attend/v1/Abnormal', input)
    },
    postV1Schedule(input: InPostV1ScheduleDTOAttend): Promise<ResponseEntity<OutPostV1ScheduleDTOAttend>> {
        return callWithBody('POST', '/api/attend/v1/Schedule', input)
    },
    getV1Schedule(input: Partial<InGetV1ScheduleDTOAttend>): Promise<ResponseEntity<OutGetV1ScheduleDTOAttend[]>> {
        return callWithQuery('GET', '/api/attend/v1/Schedule', input)
    },
    putV1Schedule(input: InPutV1ScheduleDTOAttend): Promise<ResponseEntity<OutPutV1ScheduleDTOAttend>> {
        return callWithBody('PUT', '/api/attend/v1/Schedule', input)
    },
    postV1Shift(input: InPostV1ShiftDTOAttend): Promise<ResponseEntity<OutPostV1ShiftDTOAttend>> {
        return callWithBody('POST', '/api/attend/v1/Shift', input)
    },
    getV1Checkout(input: InGetV1CheckoutDTOAttend): Promise<ResponseEntity<OutGetV1CheckoutDTOAttend>> {
        return callWithQuery('GET', '/api/attend/v1/Checkout', input)
    },
    getV1Record(input: InGetV1RecordDTOAttend): Promise<ResponseEntity<OutGetV1RecordDTOAttend>> {
        return callWithQuery('GET', '/api/attend/v1/Record', input)
    },
    getV1Shiftrecord(input: InGetV1ShiftrecordDTOAttend): Promise<ResponseEntity<OutGetV1ShiftrecordDTOAttend[]>> {
        return callWithQuery('GET', '/api/attend/v1/Shiftrecord', input)
    },
    putV1Record(input: InPutV1RecordDTOAttend): Promise<ResponseEntity<OutPutV1RecordDTOAttend>> {
        return callWithBody('PUT', '/api/attend/v1/Record', input)
    },
    getV1Statistic(input: InGetV1StatisticDTOAttend): Promise<ResponseEntity<OutGetV1StatisticDTOAttend>> {
        return callWithQuery('GET', '/api/attend/v1/Statistic', input)
    },
}

export let backupService = {
    getV1Create(input: InGetV1CreateDTOBackup): Promise<ResponseEntity<OutGetV1CreateDTOBackup>> {
        return callWithQuery('GET', '/api/backup/v1/Create', input)
    },
    getV1List(input: InGetV1ListDTOBackup): Promise<ResponseEntity<OutGetV1ListDTOBackup>> {
        return callWithQuery('GET', '/api/backup/v1/List', input)
    },
}

export let bankService = {
    getV1Accounts(input: InGetV1AccountsDTOBank): Promise<ResponseEntity<OutGetV1AccountsDTOBank[]>> {
        return callWithQuery('GET', '/api/bank/v1/Accounts', input)
    },
    getV1Records(input: InGetV1RecordsDTOBank): Promise<ResponseEntity<OutGetV1RecordsDTOBank[]>> {
        return callWithQuery('GET', '/api/bank/v1/Records', input)
    },
    postV1Record(input: InPostV1RecordDTOBank): Promise<ResponseEntity<OutPostV1RecordDTOBank>> {
        return callWithBody('POST', '/api/bank/v1/Record', input)
    },
    putV1Record(input: InPutV1RecordDTOBank): Promise<ResponseEntity<OutPutV1RecordDTOBank>> {
        return callWithBody('PUT', '/api/bank/v1/Record', input)
    },
}

export let devService = {
    getV1Role(input: InGetRoleDTODev): Promise<ResponseEntity<any>> {
        return callWithQuery('GET', '/api/dev/v1/Role', input)
    },
}

export let helloService = {
    getV1Hello(input: any): Promise<ResponseEntity<any>> {
        return callWithQuery('GET', '/api/hello/v1/Hello', input)
    },
}

export let leaveService = {
    getV1Records(input: InGetV1RecordsDTOLeave): Promise<ResponseEntity<OutGetV1RecordsDTOLeave[]>> {
        return callWithQuery('GET', '/api/leave/v1/Records', input)
    },
    postV1Record(input: InPostV1RecordDTOLeave): Promise<ResponseEntity<OutPostV1RecordDTOLeave>> {
        return callWithBody('POST', '/api/leave/v1/Record', input)
    },
    getV1Approved(input: InGetV1ApprovedDTOLeave): Promise<ResponseEntity<OutGetV1ApprovedDTOLeave>> {
        return callWithQuery('GET', '/api/leave/v1/Approved', input)
    },
    getV1Rejected(input: InGetV1RejectedDTOLeave): Promise<ResponseEntity<OutGetV1RejectedDTOLeave>> {
        return callWithQuery('GET', '/api/leave/v1/Rejected', input)
    },
    getV1Pending(input: InGetV1PendingDTOLeave): Promise<ResponseEntity<OutGetV1PendingDTOLeave>> {
        return callWithQuery('GET', '/api/leave/v1/Pending', input)
    },
    putV1Record(input: InPutV1RecordDTOLeave): Promise<ResponseEntity<OutPutV1RecordDTOLeave>> {
        return callWithBody('PUT', '/api/leave/v1/Record', input)
    },
    getV1Al(input: InGetV1AlDTOLeave): Promise<ResponseEntity<OutGetV1AlDTOLeave>> {
        return callWithQuery('GET', '/api/leave/v1/Al', input)
    },
}

export let projectService = {
    postV1Project(input: InPostV1ProjectDTOProject): Promise<ResponseEntity<OutPostV1ProjectDTOProject>> {
        return callWithBody('POST', '/api/project/v1/Project', input)
    },
    getV1Records(input: InGetV1RecordsDTOProject): Promise<ResponseEntity<OutGetV1RecordsDTOProject[]>> {
        return callWithQuery('GET', '/api/project/v1/Records', input)
    },
    getV1Record(input: InGetV1RecordDTOProject): Promise<ResponseEntity<OutGetV1RecordDTOProject>> {
        return callWithQuery('GET', '/api/project/v1/Record', input)
    },
    putV1Record(input: InPutV1RecordDTOProject): Promise<ResponseEntity<OutPutV1RecordDTOProject>> {
        return callWithBody('PUT', '/api/project/v1/Record', input)
    },
    postV1Assign(input: InPostV1AssignDTOProject): Promise<ResponseEntity<OutPostV1AssignDTOProject>> {
        return callWithBody('POST', '/api/project/v1/Assign', input)
    },
    delV1Record(input: InDelV1RecordDTOProject): Promise<ResponseEntity<OutDelV1RecordDTOProject>> {
        return callWithQuery('DELETE', '/api/project/v1/Record', input)
    },
    getV1Schedules(input: InGetV1SchedulesDTOProject): Promise<ResponseEntity<OutGetV1SchedulesDTOProject>> {
        return callWithQuery('GET', '/api/project/v1/Schedules', input)
    },
    getV1Progress(input: InGetV1ProgressDTOProject): Promise<ResponseEntity<OutGetV1ProgressDTOProject>> {
        return callWithQuery('GET', '/api/project/v1/Progress', input)
    },
    postV1Consultant(input: InPostV1ConsultantDTOProject): Promise<ResponseEntity<OutPostV1ConsultantDTOProject>> {
        return callWithBody('POST', '/api/project/v1/Consultant', input)
    },
}

export let purchaseService = {
    postV1Record(input: InPostV1RecordDTOPurchase): Promise<ResponseEntity<OutPostV1RecordDTOPurchase>> {
        return callWithBody('POST', '/api/purchase/v1/Record', input)
    },
    getV1Records(input: InGetV1RecordsDTOPurchase): Promise<ResponseEntity<OutGetV1RecordsDTOPurchase[]>> {
        return callWithQuery('GET', '/api/purchase/v1/Records', input)
    },
    putV1Status(input: InPutV1StatusDTOPurchase): Promise<ResponseEntity<OutPutV1StatusDTOPurchase>> {
        return callWithBody('PUT', '/api/purchase/v1/Status', input)
    },
}

export let reimburseService = {
    getV1Records(input: InGetV1RecordsDTOReimburse): Promise<ResponseEntity<OutGetV1RecordsDTOReimburse[]>> {
        return callWithQuery('GET', '/api/reimburse/v1/Records', input)
    },
    postV1Record(input: InPostV1RecordDTOReimburse): Promise<ResponseEntity<OutPostV1RecordDTOReimburse>> {
        return callWithBody('POST', '/api/reimburse/v1/Record', input)
    },
    getV1Approved(input: InGetV1ApprovedDTOReimburse): Promise<ResponseEntity<OutGetV1ApprovedDTOReimburse>> {
        return callWithQuery('GET', '/api/reimburse/v1/Approved', input)
    },
    getV1Rejected(input: InGetV1RejectedDTOReimburse): Promise<ResponseEntity<OutGetV1RejectedDTOReimburse>> {
        return callWithQuery('GET', '/api/reimburse/v1/Rejected', input)
    },
    getV1Record(input: InGetV1RecordDTOReimburse): Promise<ResponseEntity<OutGetV1RecordDTOReimburse>> {
        return callWithQuery('GET', '/api/reimburse/v1/Record', input)
    },
    putV1Record(input: InPutV1RecordDTOReimburse): Promise<ResponseEntity<OutPutV1RecordDTOReimburse>> {
        return callWithBody('PUT', '/api/reimburse/v1/Record', input)
    },
    delV1Record(input: InDelV1RecordDTOReimburse): Promise<ResponseEntity<OutDelV1RecordDTOReimburse>> {
        return callWithQuery('DELETE', '/api/reimburse/v1/Record', input)
    },
    getV1Statistics(input: InGetV1StatisticsDTOReimburse): Promise<ResponseEntity<OutGetV1StatisticsDTOReimburse>> {
        return callWithQuery('GET', '/api/reimburse/v1/Statistics', input)
    },
    getV1Statistic(input: InGetV1StatisticDTOReimburse): Promise<ResponseEntity<OutGetV1StatisticDTOReimburse>> {
        return callWithQuery('GET', '/api/reimburse/v1/Statistic', input)
    },
}

export let salaryService = {
    getV1Records(input: InGetV1RecordsDTOSalary): Promise<ResponseEntity<OutGetV1RecordsDTOSalary[]>> {
        return callWithQuery('GET', '/api/salary/v1/Records', input)
    },
    getV1Record(input: InGetV1RecordDTOSalary): Promise<ResponseEntity<OutGetV1RecordDTOSalary>> {
        return callWithQuery('GET', '/api/salary/v1/Record', input)
    },
    putV1Record(input: InPutV1RecordDTOSalary): Promise<ResponseEntity<OutPutV1RecordDTOSalary>> {
        return callWithBody('PUT', '/api/salary/v1/Record', input)
    },
    getV1Statistics(input: InGetV1StatisticsDTOSalary): Promise<ResponseEntity<OutGetV1StatisticsDTOSalary>> {
        return callWithQuery('GET', '/api/salary/v1/Statistics', input)
    },
}

export let salesService = {
    postV1Order(input: InPostV1OrderDTOSales): Promise<ResponseEntity<OutPostV1OrderDTOSales>> {
        return callWithBody('POST', '/api/sales/v1/Order', input)
    },
    getV1Orders(input: InGetV1OrdersDTOSales): Promise<ResponseEntity<OutGetV1OrdersDTOSales>> {
        return callWithQuery('GET', '/api/sales/v1/Orders', input)
    },
    getV1Order(input: InGetV1OrderDTOSales): Promise<ResponseEntity<OutGetV1OrderDTOSales>> {
        return callWithQuery('GET', '/api/sales/v1/Order', input)
    },
    putV1Order(input: InPutV1OrderDTOSales): Promise<ResponseEntity<OutPutV1OrderDTOSales>> {
        return callWithBody('PUT', '/api/sales/v1/Order', input)
    },
    delV1Order(input: InDelV1OrderDTOSales): Promise<ResponseEntity<OutDelV1OrderDTOSales>> {
        return callWithQuery('DELETE', '/api/sales/v1/Order', input)
    },
    getV1View(input: InGetV1ViewDTOSales): Promise<ResponseEntity<OutGetV1ViewDTOSales>> {
        return callWithQuery('GET', '/api/sales/v1/View', input)
    },
    getV1Accounts(input: InGetV1AccountsDTOSales): Promise<ResponseEntity<OutGetV1AccountsDTOSales>> {
        return callWithQuery('GET', '/api/sales/v1/Accounts', input)
    },
    postV1Assign(input: InPostV1AssignDTOSales): Promise<ResponseEntity<OutPostV1AssignDTOSales>> {
        return callWithBody('POST', '/api/sales/v1/Assign', input)
    },
    getV1Customers(input: InGetV1CustomersDTOSales): Promise<ResponseEntity<OutGetV1CustomersDTOSales[]>> {
        return callWithQuery('GET', '/api/sales/v1/Customers', input)
    },
    postV1Customer(input: InPostV1CustomerDTOSales): Promise<ResponseEntity<OutPostV1CustomerDTOSales>> {
        return callWithBody('POST', '/api/sales/v1/Customer', input)
    },
    putV1Customer(input: InPutV1CustomerDTOSales): Promise<ResponseEntity<OutPutV1CustomerDTOSales>> {
        return callWithBody('PUT', '/api/sales/v1/Customer', input)
    },
    delV1Customer(input: InDelV1CustomerDTOSales): Promise<ResponseEntity<OutDelV1CustomerDTOSales>> {
        return callWithQuery('DELETE', '/api/sales/v1/Customer', input)
    },
}

export let systemService = {
    getV1Backup(input: InGetV1BackupDTOSystem): Promise<ResponseEntity<OutGetV1BackupDTOSystem>> {
        return callWithQuery('GET', '/api/system/v1/Backup', input)
    },
    postV1Backup(input: InPostV1BackupDTOSystem): Promise<ResponseEntity<OutPostV1BackupDTOSystem>> {
        return callWithBody('POST', '/api/system/v1/Backup', input)
    },
}

export let taskService = {
    postV1Task(input: InPostV1TaskDTOTask): Promise<ResponseEntity<OutPostV1TaskDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Task', input)
    },
    getV1Tasks(input: InGetV1TasksDTOTask): Promise<ResponseEntity<OutGetV1TasksDTOTask[]>> {
        return callWithQuery('GET', '/api/task/v1/Tasks', input)
    },
    getV1Task(input: InGetV1TaskDTOTask): Promise<ResponseEntity<OutGetV1TaskDTOTask>> {
        return callWithQuery('GET', '/api/task/v1/Task', input)
    },
    putV1Task(input: InPutV1TaskDTOTask): Promise<ResponseEntity<OutPutV1TaskDTOTask>> {
        return callWithBody('PUT', '/api/task/v1/Task', input)
    },
    delV1Task(input: InDelV1TaskDTOTask): Promise<ResponseEntity<OutDelV1TaskDTOTask>> {
        return callWithQuery('DELETE', '/api/task/v1/Task', input)
    },
    postV1Assign(input: InPostV1AssignDTOTask): Promise<ResponseEntity<OutPostV1AssignDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Assign', input)
    },
    postV1Follow(input: InPostV1FollowDTOTask): Promise<ResponseEntity<OutPostV1FollowDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Follow', input)
    },
    postV1Notes(input: InPostV1NotesDTOTask): Promise<ResponseEntity<OutPostV1NotesDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Notes', input)
    },
    getV1Notes(input: InGetV1NotesDTOTask): Promise<ResponseEntity<OutGetV1NotesDTOTask[]>> {
        return callWithQuery('GET', '/api/task/v1/Notes', input)
    },
    postV1Customer(input: InPostV1CustomerDTOTask): Promise<ResponseEntity<OutPostV1CustomerDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Customer', input)
    },
    putV1Customer(input: InPutV1CustomerDTOTask): Promise<ResponseEntity<OutPutV1CustomerDTOTask>> {
        return callWithBody('PUT', '/api/task/v1/Customer', input)
    },
    putV1Status(input: InPutV1StatusDTOTask): Promise<ResponseEntity<OutPutV1StatusDTOTask>> {
        return callWithBody('PUT', '/api/task/v1/Status', input)
    },
    postV1Consultant(input: InPostV1ConsultantDTOTask): Promise<ResponseEntity<OutPostV1ConsultantDTOTask>> {
        return callWithBody('POST', '/api/task/v1/Consultant', input)
    },
    getV1Consultant(input: InGetV1ConsultantDTOTask): Promise<ResponseEntity<OutGetV1ConsultantDTOTask[]>> {
        return callWithQuery('GET', '/api/task/v1/Consultant', input)
    },
}

export let transService = {
    getV1Records(input: InGetV1RecordsDTOTrans): Promise<ResponseEntity<OutGetV1RecordsDTOTrans[]>> {
        return callWithQuery('GET', '/api/trans/v1/Records', input)
    },
    getV1Unpaid(input: InGetV1UnpaidDTOTrans): Promise<ResponseEntity<OutGetV1UnpaidDTOTrans>> {
        return callWithQuery('GET', '/api/trans/v1/Unpaid', input)
    },
    getV1Unreceived(input: InGetV1UnreceivedDTOTrans): Promise<ResponseEntity<OutGetV1UnreceivedDTOTrans>> {
        return callWithQuery('GET', '/api/trans/v1/Unreceived', input)
    },
    getV1Paid(input: InGetV1PaidDTOTrans): Promise<ResponseEntity<OutGetV1PaidDTOTrans>> {
        return callWithQuery('GET', '/api/trans/v1/Paid', input)
    },
    getV1Received(input: InGetV1ReceivedDTOTrans): Promise<ResponseEntity<OutGetV1ReceivedDTOTrans>> {
        return callWithQuery('GET', '/api/trans/v1/Received', input)
    },
    postV1Receipt(input: InPostV1ReceiptDTOTrans): Promise<ResponseEntity<OutPostV1ReceiptDTOTrans>> {
        return callWithBody('POST', '/api/trans/v1/Receipt', input)
    },
    getV1Statistics(input: InGetV1StatisticsDTOTrans): Promise<ResponseEntity<OutGetV1StatisticsDTOTrans>> {
        return callWithQuery('GET', '/api/trans/v1/Statistics', input)
    },
}

export let uploadService = {
    postV1File(input: FormData): Promise<ResponseEntity<OutPostV1FileDTOUpload>> {
        return callWithFormData('POST', '/api/upload/v1/File', input)
    },
}

export let userService = {
    postV1Login(input: LoginDTOUser): Promise<ResponseEntity<ResLoginDTOUser>> {
        return callWithBody('POST', '/api/user/v1/Login', input)
    },
    postV1Register(input: UserSignUpDTOUser): Promise<ResponseEntity<any>> {
        return callWithBody('POST', '/api/user/v1/Register', input)
    },
    getV1Staffs(input: InGetV1StaffsDTOUser): Promise<ResponseEntity<OutGetV1StaffsDTOUser[]>> {
        return callWithQuery('GET', '/api/user/v1/Staffs', input)
    },
    getV1Department(input: InGetV1DepartmentDTOUser): Promise<ResponseEntity<OutGetV1DepartmentDTOUser[]>> {
        return callWithQuery('GET', '/api/user/v1/Department', input)
    },
    putV1Staff(input: InPutV1StaffDTOUser): Promise<ResponseEntity<OutPutV1StaffDTOUser>> {
        return callWithBody('PUT', '/api/user/v1/Staff', input)
    },
}

